<template>
  <div class="idea">
    <head-nav></head-nav>
    <about-img></about-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="idea-title">
        <img
          :src="$t('about.title-6')"
          alt=""
        >
      </h3>

      <div class="idea-box">
        <div
          class="idea-list"
          v-for="(item, index) in list"
          :key="index"
          @click="ideaList(item.id)"
        >
          <span class="list-num">
            {{ (index + 1).toString().padStart(2, '0') }}
          </span>
          <img
            class="list-img"
            :src="item.image.publicUrl"
            alt=""
          >
          <div class="list-info">
            <h4 class="list-title">
              {{item.title}}
            </h4>
            <div class="list-line">
              <img
                src="@/assets/image/home/listLine.png"
                alt=""
              >
            </div>
            <div class="list-content">
              <p class="list-text1"><img
                  v-if="yuan"
                  src="https://leewen-res.oss-cn-shanghai.aliyuncs.com/uploads/5fe00d94e8e5d32c54e72004-yuan.png"
                >{{item.subtitle[0]}}</p>
              <p
                class="list-text2"
                v-if="item.subtitle[1] !== undefined"
              ><img
                  v-if="yuan"
                  src="https://leewen-res.oss-cn-shanghai.aliyuncs.com/uploads/5fe00d94e8e5d32c54e72004-yuan.png"
                >{{item.subtitle[1]}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import returnImg from '@/assets/image/product/fanhui.png';
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
    AboutNav,
  },
  apollo: {
    list: {
      query() {
        if (this.$i18n.locale === 'cn') {
          console.log(this.$i18n.locale);
          this.iconfont = this.$i18n.locale;
          return gql`
            query {
              list: allHomePageChessboards {
                id
                title
                subtitle
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        console.log(this.$i18n.locale);
        this.iconfont = this.$i18n.locale;
        return gql`
          query {
            list: allHomePageChessboards {
              id
              title
              subtitle
              image {
                publicUrl
              }
            }
          }
        `;
      },
      update(value) {
        const newList = value.list.map((item) => ({
          ...item,
          subtitle: item.subtitle.split('\n'),
        }));
        return newList;
      },
    },
  },
  watch: {
    iconfont() {
      if (this.iconfont === 'cn') {
        this.yuan = false;
      } else {
        this.yuan = true;
      }
    },
  },
  data() {
    return {
      iconfont: '',
      yuan: true,
      list: [],
      breadcrumb: [
        {
          label: 'about.idea',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
    };
  },
  methods: {
    ideaList(id) {
      this.$router.push({
        name: 'about-idea-details',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.idea {
  .wrap {
    padding: 88px 0 150px 0;

    .idea-title {
      text-align: center;
    }

    .idea-box {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;

      .idea-list {
        cursor: pointer;
        width: 380px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        margin-bottom: 20px;
        border-radius: 10px;
        position: relative;

        .list-num {
          display: inline-block;
          width: 30px;
          text-align: center;
          height: 30px;
          line-height: 30px;
          font-size: 0.6rem;
          color: #fff;
          background: #ffbf00;
          border-radius: 50%;
          position: absolute;
          top: 14px;
          left: 14px;
        }

        .list-img {
          position: absolute;
          top: 60px;
          left: 20px;
          width: 100px;
          height: 100px;
        }

        .list-info {
          position: absolute;
          top: 50px;
          right: 30px;
          width: 220px;

          .list-title {
            font-size: 24px;
          }

          .list-content {
            p {
              margin-top: 15px;
              color: #666;

              img {
                position: relative;
                top: -6px;
                margin-right: 4px;
              }
            }
          }
        }
      }

      .idea-list:nth-child(2n + 2) {
        background: #757575;

        .list-title {
          color: #fff;
        }

        .list-content {
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
